import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/index.vue";
import base from "@/layout/base.vue";

Vue.use(VueRouter);
export const children = [
  {
    path: "/home",
    name: "home",
    meta: {
      title: "首页",
      icon: "el-icon-s-home",
    },
    component: (resolve) => require(["@/views/home/index.vue"], resolve),
  },
  
  {
    path: "/authorization",
    redirect: "/authorization/expressage",
    component: base,
    meta: {
      title: "授权管理",
      icon: "el-icon-s-custom",
    },
    children: [

      {
        path: "shopManage",
        name: "shopManage",
        meta: {
          title: "店铺管理",
          icon: "el-icon-s-shop",
          keepAlive: true,
        },
        component: (resolve) => require(["@/views/authorization/shopManage/index.vue"], resolve),
      },
      {
        path: "expressage",
        name: "expressage",
        meta: {
          title: "快递管理",
          icon: "el-icon-location",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/authorization/expressage/index.vue"], resolve),
      },
      {
        path: "addresser",
        name: "addresser",
        meta: {
          title: "发件人管理",
          icon: "el-icon-s-custom",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/authorization/addresser/index.vue"], resolve),
      },
    ],
  },
  {
    path: "/order",
    redirect: "/order/orderList",
    component: base,
    meta: {
      title: "订单管理",
      icon: "el-icon-s-order",
    },
    children: [
      {
        path: "orderList",
        name: "orderList",
        meta: {
          title: "所有订单",
          icon: "el-icon-s-order",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/order/orderList/index.vue"], resolve),
      },
      {
        path: "deliverGoods",
        name: "deliverGoods",
        meta: {
          title: "自发订单",
          icon: "el-icon-printer",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/order/deliverGoods/index.vue"], resolve),
      },
    ],
  },
  {
    path: "/goods",
    redirect: "/goods/goodsList",
    component: base,
    meta: {
      title: "商品与库存",
      icon: "el-icon-s-goods",
    },
    children: [
      {
        path: "goodsList",
        name: "goodsList",
        meta: {
          title: "商品列表",
          icon: "el-icon-s-order",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/goods/goodsList/index.vue"], resolve),
      },
      {
        path: "goodsTag",
        name: "goodsTag",
        meta: {
          title: "商品标签",
          icon: "el-icon-s-order",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/goods/tag/index.vue"], resolve),
      }
    ],
  },
  {
    path: "/reconciliation",
    redirect: "/reconciliation/manufacturer",
    component: base,
    meta: {
      title: "对账结算",
      icon: "el-icon-s-finance",
    },
    children: [
      {
        path: "manufacturer",
        name: "manufacturer",
        meta: {
          title: "厂家管理",
          icon: "el-icon-s-platform",
          keepAlive: true,
        },
        component: (resolve) =>
          require(["@/views/reconciliation/manufacturer/index.vue"], resolve),
      },
      {
        path: "merchant",
        name: "merchant",
        meta: {
          title: "商家管理",
          icon: "el-icon-s-cooperation",
          keepAlive: true,
        },
        component: (resolve) => require(["@/views/reconciliation/merchant/index.vue"], resolve),
      },
    ],
  },
];
const routes = [
  {
    path: "/",
    redirect: "/home",
    component: layout,
    children: children,
  },
  {
    path: "/login",
    name: "login",
    component: (resolve) => require(["@/views/login.vue"], resolve),
  },
  {
    path: "/callback/:id",
    name: "callback",
    meta: { title: "授权页面" },
    component: (resolve) => require(["@/views/authorization/shopManage/index.vue"], resolve),
  },
  {
    path: "/404",
    name: "NotFound",
    meta: { title: "404" },
    component: (resolve) => require(["@/views/404.vue"], resolve),
  },
  // 当什么都没有匹配到的时候，重定向页面到 404 页面
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
    name: "notMatch",
    meta: { hidden: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
