<template>
  <el-form :inline="true" :model="formData" ref="formData" label-width="auto">
    <el-form-item
      :label="item.label"
      :prop="item.prop"
      v-for="(item, index) in formSet"
      :key="index"
    >
      <el-select
        v-if="item.scope === 'options'"
        v-model="formData[item.prop]"
        :placeholder="item.placeholder"
        clearable
      >
        <el-option
          v-for="(items, indexs) in item.options"
          :key="indexs"
          :label="items.label"
          :value="items.value"
        >
        </el-option>
      </el-select>
      <slot v-else-if="item.scope === 'slot'" :name="item.slotName"></slot>
      <el-input
        v-else
        clearable
        style="max-width: 250px; min-width: 100px"
        v-model="formData[item.prop]"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm">查询</el-button>
      <el-button @click="resetForm">重置</el-button>
      <slot></slot> </el-form-item
  ></el-form>
</template>

<script>
export default {
  props: {
    formSet: {
      type: Array,
      default: () => {
        return [];
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$emit("search");
    },
    resetForm() {
      this.$refs.formData.resetFields();
      this.formData.pageNum = 1;
      this.$emit("search", 2);
    },
  },
};
</script>

<style lang="less" scoped></style>
